/* eslint-disable no-underscore-dangle */
import getConfig from 'next/config';
import { useEffect, useState } from 'react';

import { LocalConfig } from '../../next.config.types';
import { FourOhFour } from '../FourOhFour';
import { templateComponents } from './Slug.map';
import { SlugProperties, SlugServerSideProperties } from './Slug.types';
import { CmsPage } from '@tgg/common-types';
import { CmsClient } from '@tgg/micro-services/cms-client';
import { Layout, Redirect, RedirectContent } from '@tgg/ui';
import { GsspWithLoggerParameters, gsspWithLogger } from '@tgg/util';
import { applicationIdentifier } from 'apps/commerce/core/constants';

const getCmsPage = (slug: string): CmsPage | null => {
    return slug ? null : CmsPage.HomePage;
};

export function Slug({
    schema,
    isMobileSession,
    cmsPage,
    ...extraProperties
}: SlugProperties) {
    const [notFound, setNotFound] = useState(false);

    const Component = templateComponents.get(schema._meta.schema)?.component;
    const { _meta, ...componentSchemas } = schema;
    const schemaContent = componentSchemas.content || [];

    const headerSnackBar = schemaContent.find(
        item =>
            item._meta.schema ===
            'https://thegymgroup.com/libs/ui/src/lib/components/HeaderInfoSnackBar/HeaderInfoSnackBar.schema.json',
    );

    const storageKey = 'internalRedirect';

    const isError = !Component || _meta.schema === 'Error';

    /**
     * Slug catchall dynamic path can intercept routes that are intended to go to Cloudflare (as they have not been created in CMS).
     * In this scenario, force a redirect to the same URL.
     * Includes a safeguard against infinite refreshes so it doesn't break locally.
     */
    useEffect(() => {
        const hasPreviouslyRedirected = sessionStorage.getItem(storageKey);

        if (isError) {
            if (!hasPreviouslyRedirected) {
                sessionStorage.setItem(storageKey, 'true');
                window.location.replace(window.location.href);
            } else {
                setNotFound(true);
                sessionStorage.removeItem(storageKey);
            }
        }

        return () => {
            sessionStorage.removeItem(storageKey);
        };
    }, [Component, _meta, isError]);

    /**
     * This is a fallback in case we navigate here on the client side without executing the getServerSideProps.
     * There's no option to set the 404 status code here.
     */
    if (notFound) {
        return <FourOhFour />;
    }

    return isError ? null : (
        <Layout
            isMobileSession={isMobileSession}
            headerSnackBar={headerSnackBar}
        >
            <Component
                {...componentSchemas}
                {...extraProperties}
                {...{ cmsPage }}
            />
        </Layout>
    );
}

const pageParameters: GsspWithLoggerParameters = {
    applicationIdentifier,
    meta: {
        journeyInfo: {
            journeyName: 'Slug',
        },
    },
};

export const getServerSideProps = gsspWithLogger<
    SlugServerSideProperties,
    { slug: string[] }
>(async (_context, injectorParameters) => {
    const { isMobileSession, loggerParameters, logger } = injectorParameters;
    const {
        publicRuntimeConfig: { AMPLIENCE_HUB_ID },
        serverRuntimeConfig: {
            AMPLIENCE_FRESH_API_KEY,
            AMPLIENCE_FRESH_API_ON,
        },
    }: LocalConfig = getConfig();

    const cmsClient = new CmsClient({
        hubName: AMPLIENCE_HUB_ID,
        freshApiKey: AMPLIENCE_FRESH_API_KEY,
        isFreshOn: AMPLIENCE_FRESH_API_ON,
    });
    const { params, req } = _context;

    /**
     * Amplience delivery key should be in the format: about-the-gym/about-us
     * with no leading or trailing slashes
     *
     * We are assuming this will exist as an empty slug will be captured by the home page
     */
    const slug = !params?.slug ? '' : `${params?.slug.join('/')}`;

    const allRedirects = (await cmsClient.getContentItemsByType<
        'redirects',
        RedirectContent[]
    >('redirects')) as RedirectContent[];

    const redirects = allRedirects.reduce((accumulator: Redirect[], item) => {
        if (item.content) {
            return [...accumulator, ...item.content];
        }

        return accumulator;
    }, []);

    const redirect = redirects?.find(
        item => item.source.replace(/^\/|\/$/g, '') === slug,
    );

    if (redirect) {
        const { destination, statusCode } = redirect;
        const statusCodeAsNumber = Number(statusCode) as
            | 301
            | 302
            | 303
            | 307
            | 308;

        return {
            redirect: {
                destination,
                statusCode: statusCodeAsNumber,
            },
        };
    }

    /**
     * Amplience does not allow an empty delivery key, so we are using 'home' for the home page
     */
    const deliveryKey = slug || 'home';

    const schema = await cmsClient.getContentItem(deliveryKey);

    const cmsPage = getCmsPage(slug);

    /**
     * Set the status code to 404 and render the default error page
     * https://nextjs.org/blog/next-10#notfound-support
     */
    if (schema._meta.schema === 'Error') {
        const userAgent = req.headers['user-agent'];
        const isMobile = req.url?.includes('/mobile/');
        const isAndroid = userAgent?.includes('Android');
        const isIOS =
            userAgent?.includes('iPhone') || userAgent?.includes('iPad');
        const storeRedirect = isAndroid
            ? 'https://play.google.com/store/apps/details?id=com.netpulse.mobile.thegymgroup&amp;hl=en_GB&amp;gl=US'
            : 'https://apps.apple.com/gb/app/the-gym/id1444707310';
        if (isMobile && (isAndroid || isIOS)) {
            return {
                redirect: {
                    destination: storeRedirect,
                    statusCode: 307,
                },
            };
        }
        return {
            props: {
                schema,
                isMobileSession,
            },
            notFound: true,
        };
    }

    const { getServerSideProps: gSSP } =
        templateComponents.get(schema._meta.schema) || {};

    const { _meta, ...schemaProperties } = schema;

    const extraProperties = gSSP
        ? await gSSP({
              ..._context,
              // How can we get TS to know what params will come from the Amplience schema?
              // @ts-ignore
              params: {
                  ...params,
                  ...schemaProperties,
              },
          })
        : { props: {} };

    if ('redirect' in extraProperties) {
        return extraProperties;
    }

    return {
        props: {
            ...(extraProperties as any).props,
            schema,
            isMobileSession,
            cmsPage,
        },
    };
}, pageParameters);
